<p-confirmDialog key="photoConfirmDialog" header="Photo Action Confirmation"
  icon="pi pi-exclamation-triangle"></p-confirmDialog>
  <div class="h-100">
    <p-toast #uploadToast key="uploadToast"></p-toast>
    <p-fileUpload [customUpload]="true" (uploadHandler)="onUpload($event)" (onSelect)="onFileSelect($event)"
      [multiple]="true" accept="image/*,.heic" [previewWidth]="200" mode="advanced" [showUploadButton]="false"
      [showCancelButton]="false" [style]="{ width: '100%', height: '100%' }">

      <ng-template pTemplate="toolbar">
        <!-- <p-button icon="pi pi-upload" iconPos="left" label="Upload/Save" (onClick)="onUpload($event)"
          [disabled]="saveDisabled"></p-button> -->
        <p-button class="pull-right" icon="pi pi-sort-alt" iconPos="left" label="Sort by Label"
          (onClick)="arrange($event, true)" [disabled]="!files.length"></p-button>
      </ng-template>

      <ng-template pTemplate="content">
        <div *ngIf="!isLoading && files.length > 0" class="w-100 h-100">
          <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
            <div class="flex flex-row flex-wrap justify-content-start align-content-center gap-2 row-gap-2 photo-upload-content"
              dndDropzone [dndHorizontal]="true" dndEffectAllowed="move" (dndDrop)="onDrop($event)">
              <!-- Placeholder for drag-and-drop -->
              <div class="flex-none placeholder-style" dndPlaceholderRef></div>
  
              <!-- Photo item -->
              <div class="image-tile flex flex-wrap flex-column justify-content-center align-content-center align-items-center bg-white shadow-2 p-2"
                *ngFor="let file of files; let i = index;" [dndDraggable]="file" dndEffectAllowed="move">
                <!-- Image Tile with Background -->
                <div class="border-round image-container">
                  <img class="draggable-item" [src]="file.objectURL" (click)="editPhoto(file, i)" />
                </div>
                <div class="drag-image-ref border-round" dndDragImageRef>
                  <img class="drag-image" [src]="file.objectURL" style="width: 200px; height: 200px;"/>
                </div>
  
                <!-- Progress bar -->
                <div class="w-full justify-content-center align-content-center pt-2">
                  <progressbar [animate]="false" [value]="file.progress" type="info">
                    <b>{{ file.progress }}%</b>
                  </progressbar>
                </div>
  
                <!-- File name and size -->
                <div class="flex flex-column justify-content-center align-content-center w-full min-w-0 pt-2">
                  <div class="max-h-2rem text-sm flex align-items-start text-ellipsis item"  [pTooltip]="file.name">
                    {{ file.name }}
                  </div>
                  <div class="max-h-2rem text-xs flex align-items-start item">
                    {{ file.size | formatBytes }}
                  </div>
                </div>
  
                <!-- Photo label dropdown -->
                <div class="w-full pt-2 custom-dropdown">
                  <p-dropdown class="nodrag w-full" [options]="photoLabels" [(ngModel)]="file.label"
                    placeholder="Select a photo label..." optionLabel="label" optionValue="value" [showClear]="true"
                    (onChange)="saveLabel(file, $event)"></p-dropdown>
                </div>
  
                <!-- Action buttons -->
                <div class="w-full flex justify-content-center align-content-center gap-2 pt-2">
                  <button pButton pRipple type="button" icon="pi pi-pencil" (click)="editPhoto(file, i)"
                    class="p-button-rounded p-button-text p-button-info nodrag" pTooltip="Rotate and crop the photo"
                    tooltipPosition="top"></button>
                  <button pButton pRipple type="button" icon="pi pi-trash" (click)="deletePhoto(file, i)"
                    class="p-button-rounded p-button-text p-button-danger nodrag" pTooltip="Remove the photo"
                    tooltipPosition="top"></button>
                </div>
              </div>
            </div>
          </p-scrollPanel>  
        </div>
      </ng-template>
      <ng-template pTemplate="file"> </ng-template>
      <ng-template pTemplate="empty">
        <div class="h-full w-full flex flex-column flex-wrap justify-content-center align-items-center p-8" style="min-width: 100% !important; min-height: 100% !important">
          <div *ngIf="!isLoading && !files.length; else elseBlock" class="text-5xl text-center text-gray-500 vertical-align-middle" style="min-width: 100% !important; min-height: 100% !important">
            No photos have been uploaded yet. Click the choose button to start adding photos.
          </div>
          <ng-template #elseBlock>
            <div class="text-5xl text-center text-gray-500 vertical-align-middle" style="min-width: 100% !important; min-height: 100% !important">
              Loading...
            </div>
          </ng-template>
        </div>
      </ng-template>
    </p-fileUpload>
</div>