import {Component, OnInit, ChangeDetectorRef, Input, ViewChild, Output, EventEmitter, output} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {MessageService} from 'primeng/api';
import loadImage from 'blueimp-load-image';
import {ISqlJob} from '../../jobs/sql-job.interface';
import {PhotoEditorComponent} from '../editor/photo-editor.component';
import {DialogService} from 'primeng/dynamicdialog';
import {ConfirmationService} from 'primeng/api';
import { v4 as uuid4 } from 'uuid';
import { PhotosService } from '../photos.service';
import {IFile} from './upload.models';
import padStart from 'lodash/padStart';
import {HttpEvent} from '@angular/common/http';
import { Job } from '../../jobs/job';
import heic2any from 'heic2any';
const orderBy = require('lodash/orderBy');
const sortBy = require('lodash/sortBy');
import { Loading, Block } from 'notiflix';
import { checkImage, HEIC, isHEIC } from 'check-image-type';
// import Sortable, { Options as SortableOptions } from 'sortablejs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
// import { GridsterConfig, GridsterItem } from 'angular-gridster2';
import {
  DndDraggableDirective,
  DndDropEvent,
  EffectAllowed,
} from 'ngx-drag-drop';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  files: any[] = [];
  job: any;
  draggedFile: any;
  photoBucket: string = '';
  sortablejsOptions: any;
  photoLabels: any[] = [];
  saveDisabled = true;
  filesToSign: IFile[] = [];
  // sortableOptions: SortableOptions = {};
  // sortablejs: Sortable | null = null;
  // sortableList: Sortable | null = null;
  // options: GridsterConfig = {};
  // dashboard: GridsterItem[] = [];
  effectAllowed: EffectAllowed = 'all';
  isLoading = true;

  @Input() scJob: ISqlJob = {} as ISqlJob;

  @Output() hasUnsavedPhotoChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

  hasPhotos = output<boolean>();

  constructor(
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private photosService: PhotosService,
    private dialogService: DialogService,
  ) {
    console.log('UploadComponent constructor');
    // this.sortableOptions = {
    //   multiDrag: true,
    //   avoidImplicitDeselect: true,
    //   direction: 'horizontal',
    //   selectedClass: 'sortable-selected',
    //   fallbackTolerance: 75,
    //   animation: 75,
    //   scroll: true,
    //   scrollSensitivity: 100,
    //   scrollSpeed: 10,
    //   handle: '.handle',
    //   delay: 0,
    //   removeCloneOnHide: true,
    //   onSelect: (event: any) => {
    //   },
    //   onDeselect: (event: any) => {
    //   },
    //   onStart: (event: any) => {
    //   },
    //   onEnd: (evt: any) => {
    //   }
    // };
  }

  // onDragStart(event: DragEvent) {
  //   console.log('dragStart:', event);
  //   this.messageService.add({
  //     severity: 'success',
  //     summary: 'onDragStart event fired!',
  //   });
  // }

  // onDragged(event: DragEvent, item: any) {
  //   console.log('Dragged:', event);
  //     // const index = this.files.indexOf(item);
  //     // this.files.splice(index, 1);

  // }

  // onDragEnd(event: DragEvent) {
  //   console.log('dragEnd:', event);
  //   this.messageService.add({
  //     severity: 'success',
  //     summary: 'onDragEnd event fired!',
  //   });
  // }

  onDrop(event: DndDropEvent) {
    const index = this.files.findIndex(f => f.id === event.data.id);
    if (event.index !== undefined && index !== -1) {
      moveItemInArray(this.files, index, event.index);
      this.savePhotos()
      .then(() => {
        this.messageService.add({
          key: 'uploadToast',
          severity: 'success',
          summary: 'Update Successful',
          detail: 'Photo position has been updated',
        });
      });
    }
  }

  ngOnInit(): void {
    // this.options = {
    //   itemChangeCallback: this.itemChange,
    //   itemResizeCallback: this.itemResize,
    //   draggable: {
    //     enabled: true
    //   },
    //   resizable: {
    //     enabled: true
    //   }
    // };

    // this.dashboard = [
    //   {cols: 2, rows: 1, y: 0, x: 0},
    //   {cols: 2, rows: 2, y: 0, x: 2}
    // ];

    this.sortablejsOptions = {
      onStart: (event: any) => {
        this.cd.detach();
      },
      onEnd: async (evt: any) => {
        this.messageService.add({
          key: 'uploadToast',
          severity: 'success',
          summary: 'Photo Changes Saved',
        });

        await this.savePhotos()
          .then(() => {
            this.messageService.add({
              key: 'uploadToast',
              severity: 'success',
              summary: 'Photo Changes Saved',
            });
          });
      },
      scroll: true,
      scrollSensitivity: 100,
      filter: '.nodrag',

    };

    this.photosService.getJob(this.scJob.jobId)
      .then(job => {
        this.job = job;
        if (job.inspectionPhotos.length) {
          this.hasPhotos.emit(true);
          return this.loadPhotos(job.inspectionPhotos);
        }
        this.hasPhotos.emit(false);
        return;
      })
      .then(() => {
        return this.photosService.getS3PhotoBucket();
      })
      .then(bucket => {
        this.photoBucket = bucket;
        return this.photosService.getPhotoLabels();
      })
      .then(photoLabels => {
        this.photoLabels = sortBy(photoLabels, ['label']);
        this.photoLabels = this.photoLabels.map(l => {
          return {
            index: l.index,
            label: l.label,
            value: l.label
          };
        });
        // this.arrange(null, false);
      })
      .finally(() => {
        this.isLoading = false;
        this.cd.detectChanges();
      });
  }

  async loadPhotos(photos: any): Promise<void>{
    for (const [pIdx, p] of photos.entries()) {
      const imageArrayBuf = await this.photosService.getS3Photo(p.url);
      const imageFile: any = new File([imageArrayBuf], p.name, { type: p.type });
      imageFile.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(imageFile)));
      imageFile.resized = true;
      imageFile.id = p.photoId;
      imageFile.url = p.url;
      imageFile.label = p.label;
      imageFile.bucket = p.bucket || this.photoBucket;
      imageFile.key = p.key;
      imageFile.index = (p.index || p.index === 0) ? p.index : pIdx;
      imageFile.uploaded = true;
      imageFile.progress = 100;
      this.files.push(imageFile);
    }
  }

  async onUpload(event: any): Promise<void> {
    await this.signFiles();
    Promise.all(
      this.files.map(async f => {
        if (!f.uploaded) {
          return this.uploadPhoto(f);
        }
      })
    )
    .then( () => {
      this.savePhotos()
        .then(() => {
          this.messageService.add({
            key: 'uploadToast',
            severity: 'success',
            summary: 'Photo Upload Successful',
            detail: `Photos have been successfully upload and saved.`
          });
          this.saveDisabled = true;
          this.hasUnsavedPhotoChanges.emit(false);
          this.hasPhotos.emit(true);
        });
    })
    .catch(e => {
      this.messageService.add({
        key: 'uploadToast',
        severity: 'danger',
        summary: 'Photo Upload Failed',
        detail: e
      });
    });
  }

  async savePhotos(): Promise<Job> {
    const photos = this.files.filter(f => f.uploaded === true).map(f => {
        // @ts-ignore
        return {
          photoId: f.id,
          name: f.name,
          type: f.type,
          bucket: f.bucket || this.photoBucket,
          key: f.key,
          size: f.size,
          url: f.url,
          label: f.label,
          index: f.index
        };
    });
    return await this.photosService.savePhotos(this.scJob.jobId, photos);
  }

  uploadPhoto(file: File): Promise<File|void> {
    return new Promise((resolve, reject) => {
      try {
        this.photosService.uploadPhotoS3(file).subscribe( (event: HttpEvent<any>) => {
          try {
            switch (event.type) {
              case 1: // progress
                // @ts-ignore
                file.progress = parseInt(100.0 * event.loaded / event.total, 10);
                break;
              case 4: // finished
                // check for success and update the file
                // @ts-ignore
                if (event.status === 200) {
                  // @ts-ignore
                  file.uploaded = true;
                  // @ts-ignore
                  file.progress = 100;
                  this.messageService.add({
                    key: 'uploadToast',
                    severity: 'success',
                    summary: 'Photo Uploaded Successfully',
                    detail: `${file.name} was uploaded.`
                  });
                  resolve(file);
                } else {
                  console.log(`Unable to upload ${file.name}`);
                  console.log(event);
                  this.messageService.add({
                    key: 'uploadToast',
                    severity: 'error',
                    summary: 'Photo Upload Failed',
                    detail: `${file.name} was not uploaded!`
                  });
                  reject(new Error('Unable to upload photo'));
                }
                // todo: need o add error handling
                break;
            }
          } catch (e: any) {
            reject(e);
          }
  
        });
  
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    });
  }

  async onFileSelect(event: any): Promise<void> {
    // const filesToSign: IFile[] = [];
    try {
      Loading.standard('Preparing upload...');
      this.saveDisabled = false;
      this.hasUnsavedPhotoChanges.emit(true);
      let totalUploaded = 0;
      for (let [index, f] of event.currentFiles.entries()) {
        // check file type
        const buf = new Uint8Array(await f.slice(0, 64).arrayBuffer());
        const fileType = checkImage(buf);
        if (isHEIC(buf)) {
          Loading.change(`Converting HEIC to JPG (${index+1} of ${event.currentFiles.length})...`);
          const convertedImage = await heic2any({
            blob: f,
            toType: 'image/jpeg',
            quality: 1.0
          });
          // @ts-ignore
          convertedImage.name = f.name;
          f = convertedImage;
        }
  
        Loading.change(`Resizing ${index+1} of ${event.currentFiles.length}...`);
  
        if (fileType?.mime.includes('image/')) {
          if (!f.hasOwnProperty('resized')) {
            const image = await loadImage(f, { maxWidth: 1200, canvas: true }) as any;
            const canvas: HTMLCanvasElement = image.image
            const resizedBlob: Blob = await new Promise<Blob>((resolve, reject) => {
              canvas.toBlob((blob: Blob | null) => {
                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error('Failed to resize photo.'));
                }
              }, 'image/jpeg', 0.40);
            });
  
  
            // const canvas = await loadImage(f, { maxWidth: 640, canvas: true });
            // const resizedBlob: Blob = await new Promise((resolve, reject) => {
            //   const canvasElement = canvas as unknown as HTMLCanvasElement;
            //   canvasElement.toBlob((blob) => {
            //     if (blob) {
            //       resolve(blob);
            //     } else {
            //       reject(new Error('Unable to resize image'));
            //     }
            //   }, 'image/jpeg', 0.99);
            // });
  
            Loading.change(`Uploading ${index+1} of ${event.currentFiles.length}...`);
  
            const imageFile = new File([resizedBlob], f.name, { type: 'image/jpeg'});
            const objectUrl = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(imageFile)));
            const fileIdx = event.currentFiles.findIndex((cFile: any) => cFile.name === f.name);
            if (fileIdx > -1) {
              window.URL.revokeObjectURL(event.currentFiles[fileIdx].objectURL);
              event.currentFiles[fileIdx] = imageFile;
              event.currentFiles[fileIdx].objectURL = objectUrl;
              event.currentFiles[fileIdx].resized = true;
              const fileId = uuid4();
              event.currentFiles[fileIdx].id = fileId;
              event.currentFiles[fileIdx].bucket = this.photoBucket;
              // event.currentFiles[fileIdx].url = '';
              event.currentFiles[fileIdx].label = '';
              event.currentFiles[fileIdx].uploaded = false;
              event.currentFiles[fileIdx].progress = 0;
              event.currentFiles[fileIdx].index = 0;
              const fileKey = `inspection-photos/${padStart(this.scJob.scJobId.toString(), 8, '0')}/${fileId}.jpg`;
              event.currentFiles[fileIdx].key = fileKey;
              const signedUrls = await this.photosService.getSignedUrls([{
                id: fileId,
                key: fileKey,
                contentType: 'image/jpeg'
              }]);
              event.currentFiles[fileIdx].url = signedUrls[0].signedUrl;
              this.files.push(event.currentFiles[fileIdx]);
              await this.uploadPhoto(imageFile);
  
              totalUploaded++;
            }
          }
  
        }
        Loading.change(`Adding uploaded photo${totalUploaded > 1 ? 's' : ''} to the job...`);
        await this.savePhotos();
        this.messageService.add({
          key: 'uploadToast',
          severity: 'success',
          summary: 'Photo Upload Successful',
          detail: `${totalUploaded} of ${event.currentFiles.length} photos uploaded`
        });
      }
      event.currentFiles = [];
      
  
      
    } catch (error: any) {
      Loading.remove();
      this.messageService.add({
        key: 'uploadToast',
        severity: 'error',
        summary: 'Photo Upload Failed',
        detail: `Something went wrong while uploading photos: ${error.message}`
      });
    } finally {
      Loading.remove();
    }
  }

  deletePhoto(file: any, index: number): void {
    this.confirmationService.confirm({
      key: 'photoConfirmDialog',
      message: `Delete ${file.name}${file.label ? ' (' + file.label + ')' : ''} ?`,
      accept: async () => {
        // remove the photo objectURL
        window.URL.revokeObjectURL(this.files[index].objectURL);
        const {bucket, key} = file;
        this.files.splice(index, 1);
        // remove photo
        if (file.uploaded) {
          const resp = await this.photosService.deleteS3Photo(bucket, key);
          console.log(resp);
        }
        this.savePhotos().then(() => {
          this.messageService.add({
            key: 'uploadToast',
            severity: 'success',
            summary: 'Photo Changes Saved',
            detail: `Photo ${file.name} was removed.`
          });
        });
      }
    });
  }

  editPhoto(file: any, i: number): void {
      const ref = this.dialogService.open(PhotoEditorComponent, {
        data: {
          file
        },
        header: `Editing Photo [ "${file.name.toUpperCase()}" - ${file.label ? file.label : 'No Label'} ]`,
        width: '50%',
        showHeader: true,
        closeOnEscape: false,
        closable: false,
      });

      ref.onClose.subscribe(async (cFile: any) => {
        this.files[i] = cFile;
        const signedUrls = await this.photosService.getSignedUrls([{
          id: cFile.id,
          key: cFile.key,
          contentType: 'image/jpeg'
        }]);
        this.files[i].url = signedUrls[0].signedUrl;
        await this.uploadPhoto(this.files[i]);

        // this.filesToSign.push({
        //   id: cFile.id,
        //   key: cFile.key,
        //   contentType: 'image/jpeg'
        // });
        // this.saveDisabled = false;
        // this.hasUnsavedPhotoChanges.emit(true);
      });
  }

  saveLabel(file: any, e: any): void {
    const label = this.photoLabels.find(({ value }) => value === e.value);
    if (label) {
      file.index = label.index;
    } else {
      file.index = 0;
    }
    this.savePhotos()
      .then(() => {
        this.messageService.add({
          key: 'uploadToast',
          severity: 'success',
          summary: 'Photo Label Changes Saved',
          detail: file.label
            ? `Photo ${file.name} was labeled as "${file.label}".`
            : `Photo ${file.name} label removed.`
        });
      });
  }

  dragStart(file: any): void {
    this.draggedFile = file;
    console.log('drag starting...');
    console.log(file);
  }

  dragEnd(): void {
    console.log('drag ending...');
  }

  // findIndex(file: any) {
  //   let index = -1;
  //   for(let i = 0; i < this.files.length; i++) {
  //     if (file.id === this.files[i].id) {
  //       index = i;
  //       break;
  //     }
  //   }
  //   return index;
  // }

  drop(): void {
    // if (this.draggedFile) {
    //   let draggedFileIndex = this.files.findIndex(f => this.draggedFile.id);
    //
    //   this.selectedProducts = [...this.selectedProducts, this.draggedProduct];
    //   this.availableProducts = this.availableProducts.filter((val,i) => i!=draggedProductIndex);
    this.draggedFile = null;

    console.log('item dropped...');
  }

  async signFiles(): Promise<void> {
    if (this.filesToSign && this.filesToSign.length) {
      const urls = await this.photosService.getSignedUrls(this.filesToSign);
      // assign urls to files by their id
      if (urls && urls.length) {
        for (const url of urls) {
          // let x = changeProps(this.files, { id: url.fileId }, { url: url.signedUrl });
          const file = this.files.find(f => f.id === url.fileId);
          if (file) {
            file.url = url.signedUrl;
            file.bucket = url.bucket;
          }
        }
      }
      this.filesToSign = [];
    }
  }

  arrange(e: any, canSave: boolean): void {
    for (const [pIdx, p] of this.files.entries()) {
      // sync label index
      if (p.label) {
        const label = this.photoLabels.find(l => l.label === p.label);
        if (label) {
          p.index = label.index;
        }
      } else {
        p.index = pIdx;
      }
    }
    this.files = orderBy(this.files, ['index'], ['asc']);
    if (canSave) {
      this.savePhotos()
        .then(() => {
          this.messageService.add({
            key: 'uploadToast',
            severity: 'success',
            summary: 'Photo Sort Order Saved',
          });
        });
    }
  }

  // onDragStart(event: any, file: any) {
  //   // event.dataTransfer.setData('img', JSON.stringify(img));
  //   console.log(event);
  // }

  // onDragEnd(event: any) {
  //   // save
  //   this.messageService.add({
  //     severity: 'success',
  //     summary: 'Photo Changes Saved',
  //   });
  // }

  // onDrop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.files, event.previousIndex, event.currentIndex);
  // }

}
